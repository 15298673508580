.SideListItem {
  width: fit-content;
  padding: 10px 20px;
  cursor: pointer;
  height: 100%;
  display: flex;
}

.SideListItem:hover,
.ActiveItem {
  border-bottom: 2px solid #6a0505;
}

/* .SideListItem:hover .ListTitle,
.ActiveItem .ListTitle {
  color: #068399;
} */

.ListTitle {
  color: #6a0505;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
}

.ListTitle div {
  display: flex;
  align-items: center;
}

.ListIcon {
  margin-right: 10px;
  font-size: 20px !important;
}

.nested, .nested:hover {
  width: 90%;
  margin-left: 10%;
  padding: 20px 30px 20px 20px;
}