.App {
  z-index: 5;
  box-sizing: border-box;
}

.btn {
  display: flex;
  justify-content: space-between !important;
  font-size: var(--button) !important;
}

.paginationCount {
  margin-top: 30px;
}

.paginationCount .MuiPagination-ul {
  justify-content: center;
}

.paginationCount .Mui-selected {
  background-color: #6a0505 !important;
  color: white !important;
}

.mainContainer {
  padding: 40px 50px;
  width: 100%;
}

.searchBar {
  height: 40px !important;
}

.Input {
  margin: 10px 0 !important;
}

.full {
  width: 100% !important;
}

#selectGender {
  margin: 10px 0 !important;
}

.CourseSelect-placeholder .MuiSelect-select {
  color: rgba(0, 0, 0, 0.4) !important;
}

.ImageInput {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1;
}

.DataInput {
  width: 100%;
  margin: 5px 0 !important;
}

/* Chat */
.BotChat {
  display: none;
  position: absolute;
  left: 470px;
  z-index: 1;
}

.ChatLogo {
  display: none;
  position: absolute;
  left: 70px;
  width: 110px;
  height: 110px;
  cursor: pointer;
  z-index: 1;
}

.BotChatText {
  margin: 5px 0 5px 20px;
  border-radius: 8px 0 8px 8px;
  background-color: #efefef;
  max-width: 310px;
}

.UserChatText {
  margin: 5px 20px 5px 0;
  border-radius: 0 8px 8px 8px;
  background-color: #c2c2c2;
  max-width: 310px;
}

.cont {
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  background-color: #f0f1f3;
}

.FormTitle {
  font-size: 30px !important;
  font-weight: bold !important;
}

.SmallFormWrapper {
  padding: 20px;
}

.SmallFormWrapper .LeftForm,
.SmallFormWrapper .RightForm {
  width: 45% !important;
  display: inline-block;
}

.SmallFormWrapper .aboutLabel {
  margin: 0 30px;
}

.FormContainer {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.searchBar {
  min-width: 200px;
  align-items: center;
}

.searchBar .MuiInput-input {
  align-self: center;
}

.header .searchBar .ForwardRef-searchContainer-7 {
  line-height: 0;
}

.loadingBox {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.loadingSmallBox {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
}

.LeftForm .MuiButtonBase-root {
  color: #fff !important;
  padding: 6px 16px !important;
  border-radius: 4px !important;
  background-color: #1976d2 !important;
}


.LeftForm .MuiCheckbox-root {
  padding: 2.5px !important;
}

.LeftForm .MuiInputBase-input,
.RightForm .MuiInputBase-input {
  height: 1.4375em !important;
  padding: 16.5px 14px !important;
}

.error {
  font-size: 12px;
  color: red;
  margin-top: -5px;
  margin-left: 5px;
  margin-bottom: 10px;
}

h1, h2, h3, h4, p {
  word-wrap: break-word;
}

.errorTop {
  font-size: 12px;
  color: red;
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 10px;
}

.chooseStudents .MuiOutlinedInput-root {
  height: 100% !important;
}

.tableCont {
  width: 100% !important;
}

.FormContainer .uploadButton {
  width: 40px !important;
  height: 40px;
  padding: 0 !important;
  border-radius: 50% !important;
  position: absolute;
  right: 5px;
  bottom: 0;
  min-width: auto;
}

.FormContainer .RemovePhoto {
  width: 30px !important;
  height: 30px;
  padding: 0 !important;
  border-radius: 50% !important;
  position: absolute;
  right: 5px;
  top: 35px;
  min-width: auto;
  background-color: #D11A2A !important;
}

.RemovePhoto:hover {
  background-color: #af0a17 !important;
}

.uploadButton .MuiButton-startIcon {
  margin: 0;
}

.RemovePhoto .MuiButton-startIcon {
  margin: 0;
}

.activatedTick {
  color: green;
  font-size: 30px !important;
}

.NonActivatedTick {
  color: red;
  font-size: 25px !important;
}

ul li {
  list-style-type: none;
}

@media (max-width: 1124px) {

  td,
  th {
    font-size: 12px !important;
  }

  .mainContainer {
    padding: 15px 10px;
    width: 100%;
  }

  .cont {
    min-width: 100%;
    /* width: fit-content; */
    /* overflow-x: auto; */
    padding: 10px !important;
  }

  .header {
    width: fit-content;
    min-width: 100%;
    /* position: sticky; */
    flex-direction: column;
    align-items: flex-start;
  }

  th,
  td {
    padding: 10px !important;
  }

  .searchBar {
    width: 100%;
    align-items: center;
    height: 40px !important;
  }

  .addButton {
    font-size: 14px !important;
    height: 40px !important;
    padding: 0 20px !important;
    margin-top: 10px !important;
  }

  .editButton {
    font-size: 14px !important;
    height: 40px !important;
    padding: 0 40px !important;
    margin-top: 10px !important;
  }

  .classButton {
    font-size: 14px !important;
    height: 30px !important;
    padding: 0 30px !important;
    margin-top: 10px !important;
  }

  .studentButton {
    font-size: 14px !important;
    height: fit-content !important;
    padding: 3px 5px !important;
    margin-top: 5px !important;
  }

  .SmallFormWrapper {
    max-height: 78vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
  }

  .FormContainer {
    flex-direction: column;
  }

  .FormTitle {
    font-size: 20px !important;
  }

  .SmallFormWrapper .LeftForm,
  .SmallFormWrapper .RightForm {
    width: 100% !important;
  }

  .SmallFormWrapper .RightForm .css-4jnixx-MuiStack-root {
    padding: 0;
  }

  .SmallFormWrapper .ImageInput {
    white-space: nowrap;
  }

  .SmallFormWrapper .ImageInputButtton {
    width: 100%;
  }

  .SmallFormWrapper .aboutLabel {
    font-size: 15px;
    margin: 0 0 0 0px;
    color: rgba(0, 0, 0, 0.6);
  }
}

@media (max-width: 600px) {

  .MuiOutlinedInput-root {
    height: 50px !important;
    font-size: 12px !important;
  }

  .MuiInputLabel-root {
    font-size: 12px !important;
    margin-top: 3px;
  }

  .error {
    font-size: 10px;
  }

  .chooseStudents .MuiOutlinedInput-root {
    height: 100% !important;
  }
}