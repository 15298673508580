.nav {
    width: 100%;
    height: 80px;
    background-color: white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 70px;
}

.TabList, .TabList li {
    height: 100%;
}

.TabList {
    display: flex;
}

.rightNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.NavLogo {
    height: 95%;
}

.desktop {
    display: flex !important;
    align-items: center;
}

.desktop span {
    font-weight: 500;
    margin: 0 25px 0 10px;
}

.navIcon {
    padding: 5px;
    width: 50px;
}

.navIcon:hover, .navIcon:active {
    background-color: rgba(128, 128, 128, 0.1);
    border-radius: 5px;
}

.NavLinks {
    font-size: 17px;
    cursor: pointer;
    padding: 10px 20px;
    display: flex;
    font-weight: 500 !important;
}

.NavLinks:hover {
    cursor: pointer;
    color: #6e1010;
}


.drawer .MuiPaper-root {
    width: 100%;
    max-width: 70vw;
    min-width: 200px;
}

@media (max-width: 1124px) {
    .nav {
        padding: 0 20px;
    }

    .rightNav .login {
        display: none !important;
    }

    .menuIcon {
        color: #6a0505;
    }

    .sidebarIcon {
        display: block;
    }

    .desktop {
        display: none !important;
    }
}