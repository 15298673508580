.textfield {
    background-color: white;
}

.ApplyInput {
    background-color: white;
    margin: 15px 0 !important;
}

.phoneInput input {
    width: 100%;
    height: 57px !important;
    font-size: 16px !important;
}

.phoneInput .react-international-phone-country-selector-button {
    width: 60px;
    height: 57px !important;

}

.phoneInput ul {
    z-index: 4;
}

.phoneInput .react-international-phone-country-selector-button, .phoneInput .react-international-phone-input {
    border-color: rgba(0, 0, 0, 0.23);
}

.phoneInput .react-international-phone-country-selector-button:hover, .phoneInput .react-international-phone-input:hover {
    border-color: rgba(0, 0, 0, 0.8);
}

.SearchBar {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 5px !important;
}

.SearchBar fieldset {
    border: 0;
}

.searchIcon {
    color: rgb(158, 158, 158);
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    font-size: 35px !important;
}

.searchIcon:hover {
    background-color: rgba(128, 128, 128, 0.1);
}

.searchIcon:active {
    background-color: rgba(128, 128, 128, 0.15);
}