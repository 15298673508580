:root {
  /* -----colors----- */
  --primary: #6a0505;
  --text-primary: #000000;
  --text-secondary: #ffffff;
  /* -----fonts----- */
  --normal-font: 20px;
  --Chat-Text: 18px;
  --button: 20px;
  --form-text: 20px;
}

@font-face {
  font-family: "Cairo-Regular";
  src: local("Cairo-Regular"),
    url("./Assets/Fonts/Cairo/Cairo-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Kumbh-Regular";
  src: local("Cairo-Regular"),
    url("./Assets/Fonts/Sans/KumbhSans-Regular.ttf") format("truetype");
  font-weight: normal;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Kumbh-Regular" !important;
  text-decoration: none;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  transition: 0.2s;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 1024px) {
  /* *{
    transition: font-size 0.1s ease-in-out;
    font-size: 1.1em ;
  }
  h1{
    font-size: .9em;
  }
  h2{
    font-size: .7em;
  } */
}