.TeacherCont {
    width: 100%;
}

.confirmList li {
    padding-bottom: 10px;
}

.modalBox {
    width: 70% !important;
    margin: auto;
}

.listTitle {
    font-weight: 500;
}

.teamsCont {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
}

.teamBox {
    width: 49%;
    margin-bottom: 20px;
    background-color: #f0f1f3;
    border-radius: 10px;
    padding: 30px;
}

@media (max-width: 900px) {
    .teamsCont {
        flex-direction: column;
    }

    .teamBox {
        width: 100%;
        padding: 10px;
    }
}